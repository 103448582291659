// Use this to write your custom SCSS

//smooth scrolling
html {
  scroll-behavior: smooth;
}

// hero section home page
#hero {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 85vh;
}

#hero .row {
  justify-content: flex-start;
}

#center-hero {
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 35%;
  transform: translate(-50%, -50%);
  width: 100%;
}
@media screen and (max-width: 1400px) {
  #center-hero {
    left: 34%;
  }
}
@media screen and (max-width: 1100px) {
  #center-hero {
    left: 26%;
  }
}
@media screen and (max-width: 600px) {
  #hero {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
  }
  #hero .row {
    justify-content: center;
  }

  #center-hero {
    margin: auto !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
  }
}

//hexagons in home page
.hero-section {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
}

.hexagon-image {
  position: absolute;
  height: auto;
}

#hexagon1 {
  top: 6%;
  left: 6%;
}
#hexagon2 {
  top: 15%;
  left: 29%;
}

#hexagon3 {
  top: 60%;
  left: 4%;
}
#hexagon4 {
  top: 45%;
  left: 20%;
}
#hexagon5 {
  top: 80%;
  left: 8%;
}
#hexagon6 {
  top: 69%;
  left: 26%;
}
#hexagon7 {
  top: 10%;
  right: 25%;
}
#hexagon8 {
  top: 5%;
  right: 2%;
}
#hexagon9 {
  top: 45%;
  right: 23%;
}
#hexagon10 {
  top: 60%;
  right: 5%;
}
#hexagon11 {
  top: 75%;
  right: 27%;
}

@media screen and (max-width: 1500px) {
  .hexagon-image {
    transform: scale(0.6);
  }
  #hexagon1 {
    top: 6%;
    left: 4%;
  }
  #hexagon2 {
    top: 13%;
    left: 27%;
  }

  #hexagon3 {
    top: 58%;
    left: 2%;
  }
  #hexagon4 {
    top: 48%;
    left: 12%;
  }
  #hexagon5 {
    top: 78%;
    left: 1%;
  }
  #hexagon6 {
    top: 64%;
    left: 20%;
  }
  #hexagon7 {
    top: 8%;
    right: 24%;
  }
  #hexagon8 {
    top: 2%;
    right: 1%;
  }
  #hexagon9 {
    top: 43%;
    right: 21%;
  }
  #hexagon10 {
    top: 58%;
    right: 3%;
  }
  #hexagon11 {
    top: 73%;
    right: 25%;
  }
}
@media screen and (max-width: 700px) {
  .hexagon-image {
    display: none;
  }
}
// .content-wrapper {
//   height: 100vh;
//   padding-bottom: 200px;
// }
// footer {
//   position: fixed;
//   bottom: 0;
//   left: 0;
//   width: 100%;
//   height: 400px;
//   z-index: -1;
// }

// footer parallax effect
#content {
  min-height: 100vh;
  margin-bottom: -22px;
}
#content section:last-child {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

footer {
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
}

// disable parallax effect for smaller screens
@media (max-width: 767px) {
  #content {
    min-height: auto; 
    margin-bottom: 0; 
  }

  #content section:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  footer {
    position: static;
  }
}

// leadership page avatar hover effect
.rounded-circle {
  transition: transform 0.4s ease;
}
.rounded-circle:hover {
  transform: scale(1.06);
}

//terms page styles

#terms-content {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-bottom: -20px;
}

// home page below hero section 
#home-section2 {
  background-color: black;
}
.section-row {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 16px 10px 0px 10px;
}
.section-row h5 {
  color: white;
  font-size: 24px;
}
.section-row p {
  color: white;
  opacity: 60%;
  font-size: 16px;
  font-weight: 100;
}
.section-row .section {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.section-row .section:not(:last-child)::after {
  content: '';
  height: 80px;
  width: 1px;
  background-color: #D9D9D9;
  margin: 0 20px;
}
.icon {
  margin-right: 20px;
}
@media screen and (max-width: 1000px) {
  .section-row .section:nth-child(2)::after {
    display: none;
  }
  .section-row h5 {
    font-size: 20px;
  }
  .section-row p {
    font-size: 12px;
  }
}
@media screen and (max-width: 770px) {
  .icon {
    width: 70px;
  }
 
  .section-row .section:not(:last-child)::after {
    display: none;
  }
  .section-row h5 {
    font-size: 18px;
  }
  .section-row p {
    font-size: 10px;
  }
}

// styles for home page facts section
#swiper-container {
  height: 760px;
}
.tag {
  display: inline-block;
  padding: 10px 20px;
  background-color: #FBB401;
  color: black;
  border-radius: 50px;
  font-size: 16px;
  text-align: center;
  margin-bottom: 25px;
}
.tag-transparent {
  background-color: transparent;
  color: white;
  border: 2px solid white;
  padding: 5px 10px;
  font-size: 14px;
  margin-bottom: 10px;
}

.carousal-text-container {
  width: 450px; 
  margin-left: 25%;
}

@media screen and (max-width: 1120px) {
  .carousal-text-container {
     margin-left: 10%; 
   }
 }
 @media screen and (max-width: 990px) {
  .carousal-text-container {
    width: 350px; 
     margin-left: 10%; 
   }
 }
 @media screen and (max-width: 767px) {
  .swiper-button {
    display: none;
  }
  .carousal-text-container {
    width: 100%; 
    margin-left: 0;

   }
   #swiper-container {
    height: 660px;
  }
 }



.swiper-controls .swiper-pagination .swiper-pagination-bullet{
  background-color: #262B32 !important;
} 

// hiring section styles 
@media (max-width: 990px) {
  .responsive-bg {
    background: #F7F7F7 !important;
  }
  .responsive-container {
    justify-content: center !important;
    align-items: center !important;
  }
  .text-responsive {
    text-align: center !important;
  }
}