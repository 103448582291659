//----------------------------------------------------------//
//	STICKY FOOTER
//----------------------------------------------------------//
html,
body {
  height: 100%;
}
body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.content-wrapper,
footer {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.content-wrapper {
  -ms-flex-positive: 1;
  flex-grow: 1;
}

#socials-bigger-screens{
  display: inline;
}
#socials-smaller-screens {
  display: none;
}

@media (max-width: 990px) {
  #socials-smaller-screens {
    display: inline !important;
  }
  #socials-bigger-screens{
    display: none !important;
  }
}

#footer-logo-smaller-screens {
  display: none;
}
#footer-logo-bigger-screens{
  display: inline;
}

@media (max-width: 990px) {
  #footer-logo-smaller-screens {
    display: inline !important;
  }
  #footer-logo-bigger-screens{
    display: none !important;
  }
}